/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import AuthContext from "../context/AuthContext"
import Register from "../components/Auth/Register"
// import {isOnMobile} from '../utils'

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const RegisterPage = ({ location }) => {
  const { loggedInState } = useContext(AuthContext)

  const post_id = -2 //-2 means no post id
  const seo = {
    yoastTitle: "Register",
    yoastDescription:
      "Create an account with The Mattress Warehouse for a faster checkout process, order tracking, and more.",
    yoastMeta: `[
      {"property":"og:title","content":"Register"},
      {"property":"og:site_name","content":"The Mattress Warehouse"}
    ]`,
    yoastSchema: "",
    woocommerceSchema: "",
  }

  /* This should prevent loading Login page when already logged in */
  useEffect(() => {
    if (loggedInState === true) {
      navigate(`/my-account/`)
    }
  }, [loggedInState])

  return (
    <Layout location={location} blank>
      <SEO title="Register" location={location} post_id={post_id} seo={seo} />
      <Register location={location} />
    </Layout>
  )
}

export default RegisterPage
